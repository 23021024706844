import { NewHeader } from "../../../../components/NewHeader"
import { Button } from "../../../../components/ui/Button"
import Container from "../../../../components/ui/Container"
import Row from "../../../../components/ui/Row"
import { DashMenu, DashboardHeader } from "../../../Dashboard/view/styles"
import Column from "../../../../components/ui/Column"
import { ButtonsContainer } from "./styles"
import { AssetForm } from "./components/AssetForm"
import { useForm } from "react-hook-form"
import { AssetSchema, assetSchema, defaultValues } from "./schema"
import { zodResolver } from "@hookform/resolvers/zod"
import { useNavigate, useParams } from "react-router-dom"
import { useAddAssetMutation } from "../../../../store/store"
import { toast } from "react-toastify"
import { useEffect } from "react"
import {
  useAddAssetTreeMutation,
  useFetchAssetTreeQuery,
  useUpdateAssetTreeMutation,
} from "../../../../store/api/AssetsApi"

export const CreateAsset = () => {
  const navigate = useNavigate()
  const { parentId } = useParams()

  const [addAsset, mutation] = useAddAssetMutation()

  const [addAssetTree] = useAddAssetTreeMutation()

  const [updateAsset] = useUpdateAssetTreeMutation()

  const { data: parentAsset } = useFetchAssetTreeQuery(parentId ?? "")

  const form = useForm<AssetSchema>({
    resolver: zodResolver(assetSchema),
    defaultValues,
  })

  const handleCreateAsset = () => {
    form.handleSubmit((data) => {
      if (!parentId) {
        toast.error("Erro ao criar ativo")
        console.error("Parent id is required")
        return parentId
      }

      addAsset(data)
    })()
  }

  const handleCancel = () => {
    navigate("/assets")
  }

  useEffect(() => {
    if (mutation.isError) {
      toast.error("Erro ao criar ativo")
      return
    }

    if (mutation.isSuccess) {
      addAssetTree({
        id: mutation.data.id,
        name: mutation.data.name,
        type: mutation.data.type,
      })

      if (parentAsset) {
        updateAsset({
          id: parentAsset.id,
          children: [...parentAsset.children, mutation.data.id],
        })
      }

      toast.success("Ativo criado com sucesso")

      navigate("/assets")
    }
  }, [addAssetTree, mutation, parentAsset, updateAsset, navigate])

  return (
    <Container>
      <Row>
        <DashboardHeader>
          <NewHeader
            page={{
              title: "Criar Ativo",
            }}
          />

          <DashMenu>
            <ButtonsContainer>
              <Button variant='secondary' onClick={handleCancel}>
                Cancelar
              </Button>
              <Button onClick={handleCreateAsset} disabled={mutation.isLoading}>
                {mutation.isLoading ? "Carregando..." : "Salvar"}
              </Button>
            </ButtonsContainer>
          </DashMenu>
        </DashboardHeader>
      </Row>

      <Row>
        <Column md='24' lg='24' xl='24'>
          <AssetForm form={form} />
        </Column>
      </Row>
    </Container>
  )
}
