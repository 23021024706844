import { Container, Header, Content } from "./styles"
import { Title } from "../../../../../components/Title"
import { TitleAndText } from "../../../../../components/TitleAndText"
import { AssetPropertiesDTO } from "../../../../../utils/entities/assets"
import { useAppTranslate } from "../../../../../translate/useAppTranslate"
import { Skeleton } from "@mui/material"

interface AssetPropertiesProps {
  properties: AssetPropertiesDTO
  skeleton?: boolean
}

const SkeletonComponent = () => {
  return (
    <>
      <Header>
        <Skeleton variant='text' width={100} height={20} />
      </Header>

      <Content>
        {Array.from({ length: 4 }, (_, index) => (
          <TitleAndText skeleton={true} key={index} text='' title='' />
        ))}
      </Content>
    </>
  )
}

export const AssetProperties = ({
  properties,
  skeleton,
}: AssetPropertiesProps) => {
  const { assets } = useAppTranslate()

  return (
    <Container>
      {skeleton ? (
        <SkeletonComponent />
      ) : (
        <>
          <Header>
            <Title label={assets.properties} size='sm' weight='medium' />
          </Header>

          <Content>
            {Object.entries(properties).map(([key, value]) => (
              <TitleAndText key={key} title={key} text={value} />
            ))}
          </Content>
        </>
      )}
    </Container>
  )
}
