import { Gear } from "@phosphor-icons/react"
import { Button } from "../../../../../../../components/ui/Button"
import { Container } from "./styles"
import { IconButton } from "../../../../../../../components/ui/IconButton"
import { useAppTranslate } from "../../../../../../../translate/useAppTranslate"

interface ConfigAssetProps {
  onClick?: () => void
}

export const ConfigAsset = ({ onClick }: ConfigAssetProps) => {
  const { buttons } = useAppTranslate()

  return (
    <Container>
      <Button className='desktop-button' variant='secondary'>
        {buttons.configure}
      </Button>
      <IconButton
        className='mobile-icon-button'
        variant='secondary'
        icon={<Gear size={22} />}
      />
    </Container>
  )
}
