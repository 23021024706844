import { HTMLAttributes, ReactNode } from "react"
import { TreeItemIndex, TreeItemRenderContext } from "react-complex-tree"
import { AssetIcon } from "../../../AssetIcon"
import {
  AddAssetButton,
  ContainerWithChildren,
  Content,
  ExpandAssetButton,
  Wrapper,
  ItemContainer,
} from "./styles"
import { BsPlus } from "react-icons/bs"
import { CaretDown } from "phosphor-react"

interface TreeItemRendererProps
  extends Omit<HTMLAttributes<HTMLLIElement>, "title"> {
  itemIdx: TreeItemIndex
  context: TreeItemRenderContext<"expandedItems">
  depth: number
  children?: ReactNode
  type: number
  childrenAsset: TreeItemIndex[] | undefined
  label: string
  handleOnClick: (itemIdx: TreeItemIndex) => void
  handleAsset: (id: string) => void
}

export const TreeItemRenderer = ({
  itemIdx,
  context,
  children,
  childrenAsset,
  type,
  label,
  depth,
  handleAsset,
  handleOnClick,
}: TreeItemRendererProps) => {
  const handleSelectItem = () => {
    handleAsset(itemIdx.toString())
    context.selectItem()
  }

  const handleExpandItem = () => {
    if (context.expandItem)
      context.isExpanded ? context.collapseItem() : context.expandItem()
  }

  const hasChildren = !!(childrenAsset && childrenAsset.length)

  return (
    <Wrapper {...context.itemContainerWithChildrenProps}>
      <ItemContainer
        {...context.itemContainerWithoutChildrenProps}
        $selected={context.isSelected ?? false}
        onClick={handleSelectItem}
      >
        <Content
          {...context.interactiveElementProps}
          $depth={depth}
          onClick={() => {}}
        >
          {hasChildren && context.isExpanded !== undefined && (
            <ExpandAssetButton $expanded={context.isExpanded}>
              <CaretDown size={16} onClick={handleExpandItem} />
            </ExpandAssetButton>
          )}

          <AssetIcon type={type} />
          {label}
        </Content>
        <AddAssetButton>
          <BsPlus
            size={20}
            onClick={(e) => {
              e.stopPropagation()
              handleOnClick(itemIdx)
            }}
          />
        </AddAssetButton>
      </ItemContainer>
      <ContainerWithChildren>{children}</ContainerWithChildren>
    </Wrapper>
  )
}
