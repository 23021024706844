import { AssetDescriptionSkeleton } from "../../../../components/Description/Skeleton"
import { AssetPropertiesSkeleton } from "../../../../components/Properties/Skeleton"
import { AssetSensorInfoSkeleton } from "../../../../components/SensorInformation/Skeleton"
import { AssetTypeSkeleton } from "../../../../components/Type/Skeleton"
import { Divider } from "../../../../../../../components/ui/Divider"
import { Container } from "./styles"

export const AssetDetailsSkeleton = () => (
  <Container>
    <AssetDescriptionSkeleton />
    <Divider />
    <AssetTypeSkeleton />
    <Divider />
    <AssetSensorInfoSkeleton />
    <Divider />
    <AssetPropertiesSkeleton />
  </Container>
)
