import { NewHeader } from "../../../../components/NewHeader"
import { DashboardHeader } from "../../../Dashboard/view/styles"
import Container from "../../../../components/ui/Container"
import Row from "../../../../components/ui/Row"
import Column from "../../../../components/ui/Column"
import { TabList } from "../../../../components/ui/TabList"
import { Tabs } from "../../../../components/ui/Tabs"
import { TabPanels } from "../../../../components/ui/TabPanels"
import { TabPanel } from "../../../../components/ui/TabPanel"
import { Tab } from "../../../../components/ui/Tab"
import { useEffect, useMemo, useState } from "react"
import { TreeContainer } from "./components/TreeContainer"
import { HistoryCard } from "./components/HistoryCard"
import {
  AssetsContainer,
  Content,
  Wrapper,
  MessageContainer,
  HistoryComponent,
  ColumnsWrapper,
} from "./styles"
import { AssetDetails } from "./components/Details"
import { CustomDataProvider } from "../../../../components/ui/TreeAssets/CustomDataProvider"
import { Button } from "../../../../components/ui/Button"
import { TreeAssets } from "../../../../components/ui/TreeAssets"
import { TreeItemIndex } from "react-complex-tree"
import { HistoryCardSkeleton } from "./components/Skeleton/HistoryCard"
import { AssetDetailsSkeleton } from "./components/Skeleton/Details"
import { TreeAssetsSkeleton } from "./components/Skeleton/TreeAssets"
import { useFetchAssetsQuery } from "../../../../store/store"
import { useNavigate } from "react-router-dom"

export const Assets = () => {
  const [open, setOpen] = useState(true)
  const [assetDetail, setAssetDetails] = useState<string | undefined>()

  const navigate = useNavigate()

  const {
    data: listAssets,
    isError,
    isLoading,
    isFetching,
    refetch,
  } = useFetchAssetsQuery()

  const canRenderTree = useMemo(
    () => !isError && !isLoading && !isFetching,
    [isError, isLoading, isFetching]
  )

  useEffect(() => {
    if (!listAssets) return

    const assetsIds = Object.keys(listAssets)

    if (assetsIds.length === 0) return

    const id = assetsIds[0]

    setAssetDetails(listAssets[id].data.id)
  }, [listAssets])

  const onClick = () => {
    setOpen((prev) => !prev)
  }

  const dataProvider = useMemo(() => {
    if (!listAssets) {
      return new CustomDataProvider({})
    } else {
      return new CustomDataProvider(listAssets)
    }
  }, [listAssets])

  const handleAddItemClick = (itemIdx: TreeItemIndex) => {
    navigate(`/assets/create/${itemIdx}`)
  }

  const setClickAsset = (id: string) => {
    setAssetDetails(id)
  }

  return (
    <AssetsContainer>
      <Row>
        <Container>
          <DashboardHeader>
            <NewHeader
              page={{
                title: "Assets",
                subTitle: "Assets",
              }}
            />
          </DashboardHeader>
        </Container>
      </Row>

      <Row>
        <ColumnsWrapper>
          <Column sm='19' md='19' lg='19'>
            <Wrapper>
              <TreeContainer open={open} onClick={onClick}>
                {isError && (
                  <MessageContainer>
                    Error ao carregar ativos!
                    <Button variant='link' onClick={refetch}>
                      Recarregar
                    </Button>
                  </MessageContainer>
                )}

                {(isLoading || isFetching) && <TreeAssetsSkeleton />}

                {canRenderTree && (
                  <TreeAssets
                    dataProvider={dataProvider}
                    handleOnClick={handleAddItemClick}
                    handleAsset={setClickAsset}
                  />
                )}
              </TreeContainer>

              <Content>
                <Tabs>
                  <TabList>
                    <Tab>Detalhes</Tab>
                  </TabList>

                  <TabPanels>
                    <TabPanel>
                      {assetDetail ? (
                        <AssetDetails assetId={assetDetail} />
                      ) : !isError ? (
                        <AssetDetailsSkeleton />
                      ) : (
                        <MessageContainer>
                          Erro ao carregar detalhes!
                        </MessageContainer>
                      )}
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              </Content>
            </Wrapper>
          </Column>

          <Column sm='5' md='5' lg='5'>
            <HistoryComponent>
              {assetDetail ? (
                <HistoryCard assetId={assetDetail} />
              ) : !isError ? (
                <HistoryCardSkeleton />
              ) : (
                <MessageContainer>Erro ao carregar Histórico!</MessageContainer>
              )}
            </HistoryComponent>
          </Column>
        </ColumnsWrapper>
      </Row>
    </AssetsContainer>
  )
}
