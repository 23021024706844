import styled from "styled-components"

export const Container = styled.div`
  border: solid 1px ${(props) => props.theme.palette.gray._400};
  border-radius: 0px 4px 4px 4px;
  background-color: ${(props) => props.theme.palette.gray._50};
  box-sizing: border-box;
  display: flex;

  height: 100%;

  overflow-y: scroll;
`
