import { useFetchAssetQuery } from "../../../../../../store/api/AssetsApi"
import { HistoryCardSkeleton } from "../Skeleton/HistoryCard"
import { HistoryContent } from "./components/HistoryContent"
import { HistoryHeader } from "./components/HistoryHeader"

interface HistoryCardProps {
  assetId: string
}

export const HistoryCard = ({ assetId }: HistoryCardProps) => {
  const { data, error, isFetching } = useFetchAssetQuery(assetId)

  return (
    <>
      {isFetching || error ? (
        <HistoryCardSkeleton />
      ) : (
        <>
          <HistoryHeader />
          {data ? <HistoryContent historyData={data.historicalData} /> : null}
        </>
      )}
    </>
  )
}
