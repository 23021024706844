import styled from "styled-components"

export const Container = styled.div<{ $selected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${({ $selected, theme }) =>
    $selected ? theme.palette.gray._50 : theme.palette.gray._300};

  min-width: 111px;
  height: 28px;

  border-radius: 10px 10px 0px 0px;

  border-top: 1px solid ${(props) => props.theme.palette.gray._400};
  border-right: 1px solid ${(props) => props.theme.palette.gray._400};
  border-left: 1px solid ${(props) => props.theme.palette.gray._400};

  border-bottom: ${({ $selected, theme }) =>
    $selected ? "none" : `1px solid ${theme.palette.gray._400}`};

  margin-bottom: -1px;

  z-index: 1;
`
