import { AssetDescription } from "../../../components/Description"
import { AssetProperties } from "../../../components/Properties"
import { AssetSensorInfo } from "../../../components/SensorInformation"
import { AssetType } from "../../../components/Type"
import { Divider } from "../../../../../../components/ui/Divider"
import { Button } from "../../../../../../components/ui/Button"
import { formatedDate } from "../../../../../../utils/date"
import { useFetchAssetQuery } from "../../../../../../store/api/AssetsApi"
import { Container } from "./styles"
import { MessageContainer } from "../../styles"
import { AssetDetailsData } from "../../../../../../utils/entities/assets"
import { AssetDetailsSkeleton } from "../Skeleton/Details"

interface AsssetDetailsProps {
  assetId: string
}

const ErrorMessage = ({ refetch }: { refetch: () => void }) => (
  <MessageContainer>
    Erro ao carregar detalhes!
    <Button variant='link' onClick={refetch}>
      Recarregar
    </Button>
  </MessageContainer>
)

const renderAssetDetails = (data: AssetDetailsData) => {
  const { description, name, type, createdAt, sensor, properties } = data
  const formattedDate = formatedDate(createdAt)

  return (
    <>
      <AssetDescription description={description} assetName={name} />
      <Divider />
      <AssetType assetType={type} createdAt={formattedDate} />
      <Divider />
      <AssetSensorInfo
        servicesConfigured={sensor}
        hasServicesConfigured={!!sensor}
      />
      <Divider />
      <AssetProperties properties={properties} />
    </>
  )
}

export const AssetDetails = ({ assetId }: AsssetDetailsProps) => {
  const { data, error, isFetching, refetch } = useFetchAssetQuery(assetId)

  let content

  if (isFetching) {
    content = <AssetDetailsSkeleton />
  } else if (error) {
    content = <ErrorMessage refetch={refetch} />
  } else if (data) {
    content = renderAssetDetails(data)
  } else {
    content = <div>Erro ao carregar os detalhes do ativo.</div>
  }

  return <Container>{content}</Container>
}
