import { useAppTranslate } from "../../../../../translate/useAppTranslate"
import { Favorite } from "../../../../../components/Favorite"
import { ImageContainer } from "../../../../../components/ImageContainer"
import { Title } from "../../../../../components/Title"
import { TitleAndText } from "../../../../../components/TitleAndText"
import { ConfigAsset } from "./components/ConfigAsset"
import {
  AssetHeader,
  Configurations,
  Container,
  ImageAndDescription,
} from "./styles"

interface AssetDescriptionProps {
  description: string
  assetName: string
}

export const AssetDescription = ({
  description,
  assetName,
}: AssetDescriptionProps) => {
  const { assets } = useAppTranslate()

  return (
    <Container>
      <AssetHeader>
        <Title label={assetName} size='md' />
        <Configurations>
          <ConfigAsset />
          <Favorite />
        </Configurations>
      </AssetHeader>
      <ImageAndDescription>
        <ImageContainer />
        <TitleAndText text={description} title={assets.description} />
      </ImageAndDescription>
    </Container>
  )
}
