import { Bell, SignOut } from "phosphor-react"
import {
  CustomLink,
  NavTitle,
  BoxUserInfo,
  AppVersion,
  NotificationButton,
  Badge,
  NotificationBadge,
} from "../../styles/Navbar.styled"
import { Button } from "../../../ui/Button"
import { useAppDispatch, useAppSelector } from "../../../../store/hooks"
import { useAuth } from "react-oidc-context"
import { resetDashboardState } from "../../../../store/features/dashboard/dashboard.slice"
import { resetSensorsConfigState } from "../../../../store/features/sensors/sensors.slice"
import { resetUserState } from "../../../../store/features/user/user.slice"

export const NavFooter = () => {
  const dispatch = useAppDispatch()
  const { signoutRedirect } = useAuth()
  const userProfile = useAppSelector(
    (state) => state.persistedReducer.user.profile
  )

  const alarmLogCount = useAppSelector(
    (state) => state.persistedReducer.alarmsLogs.count
  )

  const logout = () => {
    dispatch(resetDashboardState())
    dispatch(resetSensorsConfigState())
    dispatch(resetUserState())
    signoutRedirect()
  }

  return (
    <>
      <NotificationButton>
        {alarmLogCount > 0 && (
          <Badge>
            <NotificationBadge>
              {alarmLogCount >= 100 ? "+99" : alarmLogCount}
            </NotificationBadge>
          </Badge>
        )}
        <CustomLink
          to='/alarmslogs'
          className={(isActive) =>
            "nav-link" + (!isActive ? " unselected" : "")
          }
        >
          <Bell className='menu-icon' size={24} />
          <NavTitle>Notificações</NavTitle>
        </CustomLink>
      </NotificationButton>
      <BoxUserInfo>
        <span className='user'>{userProfile?.email}</span>
        <span className='company' />
        <Button
          variant='link'
          leftIcon={<SignOut size={24} />}
          onClick={() => logout()}
        >
          Sair da conta
        </Button>
      </BoxUserInfo>
      <AppVersion>V 1.0</AppVersion>
    </>
  )
}
