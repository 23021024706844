import styled from "styled-components"

export const AssetsContainer = styled.div`
  font-family: ${(props) => props.theme.typography.fontFamily};
`

export const Wrapper = styled.div`
  height: 85vh;

  display: flex;
  flex-direction: row;
  align-items: end;
  justify-content: space-between;
  font-size: 16px;
`

export const ColumnsWrapper = styled.div`
  width: 100%;
  height: 85vh;
  display: flex;
  flex-direction: row;
  align-items: end;
  margin-top: 24px;
`

export const Content = styled.div`
  box-sizing: border-box;
  height: 100%;
  width: 100%;
`

export const MessageContainer = styled.div`
  font-size: medium;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 273px;
  padding: 10px 0px 10px 0px;
  gap: 10px;
`

export const HistoryComponent = styled.div`
  border: 1px solid red;
  box-sizing: border-box;
  background-color: ${(props) => props.theme.palette.gray._50};
  height: calc(85vh - 28px + 1px);
  margin-right: 10px;
  border: 1px solid ${(props) => props.theme.palette.gray._400};
  border-radius: 4px;
  padding: 16px;
  gap: 8px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
`
