import { Skeleton } from "@mui/material"
import { ImageContainer } from "../../../../../../components/ImageContainer"
import { TitleAndText } from "../../../../../../components/TitleAndText"
import {
  AssetHeader,
  Configurations,
  Container,
  ImageAndDescription,
} from "../styles"
import { useAppTranslate } from "../../../../../../translate/useAppTranslate"

export const AssetDescriptionSkeleton = () => {
  const { assets } = useAppTranslate()

  return (
    <Container>
      <AssetHeader>
        <Skeleton variant='rounded' width={150} />
        <Configurations>
          <Skeleton variant='rounded' width={80} height={40} />
          <Skeleton variant='rounded' width={35} height={30} />
        </Configurations>
      </AssetHeader>
      <ImageAndDescription>
        <ImageContainer skeleton={true} />
        <TitleAndText
          text={"description"}
          title={assets.description}
          skeleton
        />
      </ImageAndDescription>
    </Container>
  )
}
