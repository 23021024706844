import { UseFormReturn, useFieldArray, useWatch } from "react-hook-form"
import { ImageContainer } from "../../../../../../components/ImageContainer"
import { Input } from "../../../../../../components/ui/Input"
import { AssetSchema, SCHEMA_ERRORS } from "../../schema"
import {
  Wrapper,
  AssetInfo,
  BasicInfo,
  AssetDescription,
  PropertiesContainer,
} from "./styles"
import { useEffect } from "react"
import {
  AssetType,
  getProperties,
} from "../../../../../../utils/entities/assets"
import { InputSelect } from "../../../../../../components/ui/Select"
import { Divider } from "../../../../../../components/ui/Divider"
import { TextArea } from "../../../../../../components/ui/TextArea"
import { AssetSensorList } from "../../../components/SensorList"

const getErrorMessage = (message: string | undefined): string | undefined => {
  if (!message) return undefined

  switch (message) {
    case SCHEMA_ERRORS.INVALID_FILE_SIZE:
      return "O arquivo deve ter no máximo 5MB"
    case SCHEMA_ERRORS.INVALID_IMAGE_TYPE:
      return "O arquivo deve ser uma imagem"
    case SCHEMA_ERRORS.REQUIRED:
      return "Campo obrigatório"
    default:
      return "Erro desconhecido"
  }
}

const getTypeLabel = (type: number) => {
  switch (type) {
    case AssetType.SITE:
      return "Local"
    case AssetType.MOTOR:
      return "Motor"
    case AssetType.ADL:
      return "ADL"
    default:
      return ""
  }
}

interface AssetFormProps {
  form: UseFormReturn<AssetSchema>
}

export const AssetForm = ({ form }: AssetFormProps) => {
  const {
    control,
    setFocus,
    register,
    clearErrors,
    formState: { errors },
  } = form

  const { fields, append, remove } = useFieldArray({
    control,
    name: "properties",
  })

  const type = useWatch({
    control,
    name: "type",
  })

  useEffect(() => {
    if (!type) return

    const properties = getProperties(Number(type))

    remove()

    properties.forEach((property) => {
      append({ property, value: "" })
    })
  }, [remove, append, type, fields.length, setFocus])

  useEffect(() => {
    if (fields.length > 0) setFocus("type")
  }, [fields, setFocus])

  const options: number[] = Object.keys(AssetType)
    .filter((key) => !isNaN(Number(key)))
    .map((key) => Number(key))

  const handleSelectSensor = (id: string | number) => {
    form.setValue("sensorId", id.toString())
  }

  return (
    <Wrapper>
      <AssetInfo>
        <ImageContainer
          editable
          {...register("image")}
          error={getErrorMessage(errors.image?.message as string)}
          onReset={() => clearErrors("image")}
        />
        <BasicInfo>
          <Input
            label='Nome do Ativo'
            placeholder='Digite o nome'
            error={getErrorMessage(errors.name?.message)}
            {...register("name")}
          />
          <InputSelect
            label='Tipo do Ativo'
            options={options}
            error={getErrorMessage(errors.type?.message)}
            renderLabel={(option) => getTypeLabel(Number(option))}
            {...register("type")}
          />
        </BasicInfo>
      </AssetInfo>

      <Divider />

      <AssetDescription>
        <TextArea
          label='Descrição'
          rows={10}
          placeholder='Digite um descrição'
          error={getErrorMessage(errors.description?.message)}
          {...register("description")}
        />
      </AssetDescription>

      <Divider />

      <PropertiesContainer>
        {fields.map((field, index) => {
          return (
            <Input
              key={field.id}
              label={field.property}
              placeholder={`${field.property}`}
              {...register(`properties.${index}.value`)}
            />
          )
        })}
      </PropertiesContainer>

      <Divider />
      <AssetSensorList onCheck={handleSelectSensor} />
    </Wrapper>
  )
}
