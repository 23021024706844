import { useFetchSensorsQuery } from "../../../../../store/api/AssetsApi"
import { Sensor } from "../../../../../utils/entities/assets"
import { Column, Table } from "../../../../../components/Table"
import { TableSkeleton } from "../../../../../components/TableSkeleton"
import { Title } from "../../../../../components/Title"
import { Container, Content, Header, MessageError } from "./styles"

interface SensorRow {
  id: number | string
  name: string
  serialNumber: string
}

export const AssetSensorList = ({
  onCheck,
}: {
  onCheck: (id: string | number) => void
}) => {
  const { data: sensorList, isFetching, error } = useFetchSensorsQuery(1000)

  const columns: Column<SensorRow>[] = [
    {
      label: "Nome",
      render: ({ name }) => name,
    },
    {
      label: "Número de série",
      render: ({ serialNumber }) => serialNumber,
    },
  ]
  let content
  if (isFetching) {
    content = <TableSkeleton columns={columns} total={5} checkbox={true} />
  } else if (error) {
    content = <MessageError>Erro ao buscar sensores</MessageError>
  } else if (sensorList) {
    content = (
      <Table
        emptyMessage='Nenhum sensor identificado!'
        columns={columns}
        data={
          sensorList &&
          sensorList.map((sensor: Sensor) => ({
            ...sensor,
            id: sensor.serialNumber,
          }))
        }
        total={5}
        checkbox={true}
        onChangeData={(a, b) => {}}
        selectionType='radio'
        onCheck={onCheck}
      />
    )
  }

  return (
    <Container>
      <Header>
        <Title label='Sensor' size='sm' weight='medium' />
      </Header>
      <Content>{content}</Content>
    </Container>
  )
}
